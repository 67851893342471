import http from "./axios/http";


export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/get_lobby_url?t='+new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}
export function getGameUrl(gameId) {
  return http.get('/casino/get_game_url?t='+new Date().getTime(), {'gameId':gameId})
}
export function getGameList(thirdPartyCode) {
  return http.get('/casino/get_game_list?t='+new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}
export function getAccountBalance() {
  return http.get('/casino/get_account_balance?t='+new Date().getTime(), {})
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/add_member_casino_cash?t='+new Date().getTime(), {'amount':amount})
}
export function subjectMemberCasinoCash() {
  return http.get('/casino/subject_member_casino_cash?t='+new Date().getTime())
}
export function getCasinoMaintaining() {
  return http.get('/casino/get_casino_maintaining?t='+new Date().getTime())
}